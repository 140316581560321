<template>
    <div class="templateSayhi   ">

        <Head></Head>
        <div class="template-content" v-if="animationLoading && templateData">
            <div class="animation">
                <TemplateSayhiTwo :templateData="templateData" v-if="getDisplayTemplate" />
                <TemplateSayhiOne :templateData="templateData" v-else />
            </div>
        </div>
        <div class="btn-warp" v-show="animationLoading && templateData">
            <div @click.once="contimueClick(), setOptionClickAnimation(-1)" class="button active text_1"
                :class="{ 'shrink-grow-effect': activeIndex === -1 }"
                v-html="templateData.languageConfig[$language].buttonText">
            </div>

        </div>
    </div>
</template>
</template>
<script>
import webConfig from "@/config/web.config/index";
import Head from "./components/head.vue"
import { sendStatsigEvent } from "@/utils/statsig";
import { setmMxpanelUserInfo } from "@/utils/mixpanel";
import mixinTemplate from "@/mixin";
import TemplateSayhiOne from "./components/templateSayhi/templateSayhiOne.vue";
import TemplateSayhiTwo from "./components/templateSayhi/templateSayhiTwo.vue";
export default {
    name: "templateSayhi",
    mixins: [mixinTemplate],
    components: {
        Head,
        TemplateSayhiOne,
        TemplateSayhiTwo
    },
    computed: {
        getDisplayTemplate() {
            return this.$store.state.onBoardingRecordInfo[webConfig.statsigConfig.paywallParamName] ==
                webConfig.statsigConfig.selectPaywallParam
        }
    },
    data() {
        return {
            webConfig: webConfig
        }
    },
    mounted() {
        sendStatsigEvent('PlanReadyScreen', this.$store.state.onBoardingRecordInfo[this.webConfig.statsigConfig.paywallParamName]);
        // setmMxpanelUserInfo({
        //     'Experiment Name': webConfig.statsigConfig.paywallExperimentName,
        //     Parameters: [this.$store.state.onBoardingRecordInfo[webConfig.statsigConfig.paywallParamName]]
        // })
        let uid = this.$store.state.onBoardingRecordInfo[this.$utils.getKey('uid')];
        //保底避免未存入uid的情况
        uid = uid ? uid : window.sessionStorage.getItem('uid')
        this.$store.commit("updateOnBoardingRecord", {
            key: this.$utils.getKey('uid'),
            value: uid,
        });

    }

}
</script>